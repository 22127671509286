/* eslint-disable no-undef */
import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import twitter from './assets/twitter.png';
import telegram from './assets/telegram.png';
import discord from './assets/discord.png';
import logo from './assets/logo.png';
import video from './assets/Abstract_3d_hexagon_background_HD_BG.mp4';

const tweet = () => {
	window.open("https://twitter.com/monad_launchpad");
}

const discrd = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}


const About = () => {


	return (
		<div class="allWrap">
			<div class="light2">
				<div className='cont'>
					<div class="headers">

						<div class="headers2">

							<div class="logo"><img class="logoPic" src={logo} /></div>

							<div class="right">

								<div class="icons">
									<div class="socialIcon"><img onClick={tweet} src={twitter} /></div>

								</div>

							</div>

						</div>
						<div class="vidCont">
							<video class="videoBg" src={video} autobuffer autoPlay loop muted playsInline></video>
						</div>
					</div>

					<div class="container">
						<div class="content">
							<div className='title'>Coming Soon</div>
							<div className='subT'>
								Welcome to Monad Launchpad, where blockchain dreams take flight! As pioneers in digital innovation, we're thrilled to introduce a platform that transforms visions into reality. Monad Launchpad, built on the robust Monad blockchain, provides a secure and dynamic space for entrepreneurs to launch projects and connect with a global community of investors. Join us on this exciting journey as we empower visionary projects to soar to new heights. Explore Monad Launchpad and be a part of the next wave of groundbreaking blockchain initiatives!</div>
						</div>
					</div>
				</div >
			</div >
		</div >
	)

}
export default About;
